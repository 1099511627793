import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from 'react-grid-system';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';
import Img from 'gatsby-image';

import Layout from '../../components/Layouts/layout';
import SEO from '../../components/seo';
import Alert from '../../components/Alert/Alert';

const ConsumerCouncilPage = () => {
	const intl = useIntl();
	const data = useStaticQuery(graphql`
		query {
			consumersImg: file(relativePath: { eq: "montague_porchCloseUp.jpg" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return (
		<Layout
			header={intl.formatMessage({ id: 'nav.cac' })}
			breadcrumb={{
				text: <FormattedMessage id='nav.about' />,
				path: '/about/'
			}}
		>
			<SEO
				description={intl.formatMessage({
					id: 'metaTags.aboutDescription'
				})}
				lang={intl.locale}
				title={intl.formatMessage({ id: 'nav.cac' })}
			/>
			<Container>
				{intl.locale === 'fr' && (
					<Row align='stretch'>
						<Col>
							<Alert header='Attention'>
								<p lang='fr'>
									Pour les services en français, veuillez contacter le Service des licences et à la
									clientèle de l’Office de réglementation de la construction des logements au
									416-487-HCRA (4272) ou <a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>
								</p>
							</Alert>
						</Col>
					</Row>
				)}

				<Row>
					<Col md={4}>
						<Img fluid={data.consumersImg.childImageSharp.fluid} alt='' style={{ marginBottom: `2rem` }} />
					</Col>
					<Col>
						<p style={{ marginTop: `0` }}>
							In support of the HCRA's consumer protection mandate, the Consumer Advisory Council (CAC)
							provides independent and non-partisan advice to the Board on:
						</p>

						<ul>
							<li>Emerging issues in the new home construction sector</li>
							<li>Approaches to enhance consumer awareness and engagement</li>
							<li>
								Opportunities for improvement with respect to service delivery, policies and procedures
							</li>
						</ul>

						<p>
							For more information, please refer to the{' '}
							<a href='../../../CAC - Terms of Reference 03-06-2023.pdf'>CAC Terms of Reference</a>.
						</p>

						<p>Members of the HCRA CAC:</p>
						<ul>
							<li>Jay Jackson (Chair)</li>
							<li>Tony Gioventu (Vice-Chair)</li>
							<li>Sam B. Carrera</li>
							<li>Rae Dulmage</li>
							<li>Bob Fraser</li>
							<li>Agnes Maing</li>
							<li>Robert Maling</li>
							<li>Pierre Parent</li>
							<li>Donna Walrond</li>
						</ul>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};
export default ConsumerCouncilPage;
